.header--lfn {
  background: url(https://res.cloudinary.com/dudhmrdin/image/upload/v1607706905/Rectangle_57_pje10t.png);
  width: 100%;
  padding: 70px 0px;
  border-radius: 5px;
}
.header-txt--lfn {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.02em;
}
.l--bet-box {
  background: #27750a;
  padding: 20px;
}
@media (max-width: 1000px) {
  .l--bet-box {
    overflow-x: scroll;
  }
}
.l--bet {
  display: flex;
  align-items: center;
}
.l--bet > p {
  color: white;
  font-weight: 600;
  margin: 10px;
}
.first-act {
  color: #cebf09 !important;
}
.l-top-header {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
}
.l-top-header > p {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.l--content {
  background-color: #fff;
  padding: 20px 0px;
}
.l--content-con {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.l--title {
  color: #27750a;
  font-size: 18px;
  font-weight: 600;
}
.l--year {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
}
.l--back {
  display: flex;
  align-items: center;
}
.l--back > p {
  margin-left: 10px;
  color: #333333;
  font-size: 18px;
}
.l--act-details {
  margin: 40px 20px;
  text-align: center;
}
.l--act-title {
  font-size: 24px;
  font-weight: 600;
  color: #27750a;
}
.l--act-year {
  font-weight: 600;
  color: #333333;
  font-size: 24px;
  margin-top: 10px;
}
.l--act-sub {
  color: #101010;
  font-size: 18px;
  margin-top: 20px;
}
.l--act-con {
  padding: 10px 40px;
  border: 2px solid #e5e5e5;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.l--act-con > p {
  font-weight: 600;
  color: #333333;
  font-size: 20px;
}
.l-top-header-2 {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
}
.l-top-header-2 > p {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
.act--sec {
  background-color: #e5e5e5;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}
.act--sec {
  background-color: #f9f9f9;
  padding: 20px;
  display: flex;
  align-items: center;
}
.act--sec > p {
  font-size: 16px;
  color: #333333;
}
.imp-white {
  background-color: #fff !important;
}
.view--imp {
  color: #27750a !important;
  font-weight: bold !important;
}
.sec----head {
  color: #27750a;
  font-size: 18px;
  font-weight: bold;
}
.secs-desc {
  display: flex;
  margin: 10px 0px;
}
.secs-desc > p {
  color: #101010;
  font-size: 16px;
}
.l-top-header-3 {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
}
.l-top-header-3 > p {
  color: #000;
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 460px) {
  .none--phone {
    display: none;
  }
  .bock-phone {
    display: block;
  }
  .act--sec > p {
    margin: 10px 0px !important;
  }
}
