.error {
	color: red;
	margin-top: 7px;
	font-size: 0.85rem;
}

.input {
	position: relative;
	padding: 0.75rem 0;
	padding-bottom: 0 !important;
}
.input::after {
	content: none !important;
}

.input input {
	font-size: 0.95rem !important;
}

p.placeholder,
p.l {
	position: absolute;
	top: 0;
	left: 30px;
	background-color: #f7f7f7;
	padding: 0.1rem 0.75rem;
	font-size: 1.125rem;
	color: #27750a;
	font-weight: 600;
	opacity: 1;
}

.Toastify__toast-container {
	z-index: 99999999;
}

.Toastify__toast-body div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.Toastify__toast {
	padding: 8px 15px;
	border-radius: 5px;
}

.Toastify__toast--default {
	text-align: center;
}
.Toastify__close-button {
	display: none;
}
.Toastify__toast-body {
	text-align: center;
}
.Toastify__toast--error {
	background: #fef0f0;
	color: #f56c6c;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: center;
}
.Toastify__toast--success {
	color: #329c50;
	background: #ddede1;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
}
.Toastify__close-button--success {
	display: none;
}

.toast-container {
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
	&--error {
		color: #f56c6c;
		.Toastify__toast--default {
			color: #f56c6c;
			background: #fef0f0;
		}
	}
	&--success {
		.Toastify__toast--default {
			color: #329c50;
			background: #ddede1;
		}
	}
}
